<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.editCashier') }}</h5>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.active') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputSwitch v-model="user.active" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.isManager') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputSwitch v-model="user.isManager" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.username') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="user.username" @input="checkUsername" :disabled="this.isEdit()" @blur="checkUsernameAvailability()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.firstName') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="user.firstName" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.lastName') }} *</label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="user.lastName" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.email') }} </label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="user.email" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.personalid') }} </label>
                    <div class="p-col-12 p-md-2">
                        <InputText style="width: 100%" type="text" v-model="user.personalIdNumber" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.allowedTransactions') }} </label>
                    <div class="p-col-12 p-md-2">
                        <MultiSelect :showClear="true" style="width: 100%" v-model="transactionsModel" optionLabel="name" :options="transactions" :placeholder="$t('dropdown.placeholder.transactions')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.shops') }} *</label>
                    <div class="p-col-12 p-md-2" v-if="allShopsAccess">
                        <p>{{ $t('system.allShops') }}</p>
                    </div>
                    <div class="p-col-12 p-md-2" v-else>
                        <MultiSelect style="width: 100%" v-model="shopsModel" optionLabel="name" :options="shops" :placeholder="$t('dropdown.placeholder.shops')" :filter="!this.isMobile()" />
                    </div>
                </div>
                <div class="p-field p-grid" v-if="!this.isEdit()">
                    <label class="p-col-12 p-mb-2 p-md-1 p-mb-md-0">{{ $t('system.allShops') }}</label>
                    <div class="p-col-12 p-md-2">
                        <InputSwitch v-model="allShopsAccess" />
                    </div>
                </div>

                <div class="p-field p-grid">
                    <div class="p-col-12">
                        <p>{{ $t('forms.mandatoryFieldsText') }}</p>
                    </div>
                </div>
                <transition-group name="p-message" tag="div">
                    <Message :key="errorKeyCount" :severity="'error'" v-show="showError">{{ errorText }}</Message>
                </transition-group>
                <transition-group name="p-message" tag="div">
                    <Message :key="passwordKeyCount" :severity="'info'" v-show="successReset">{{ $t('system.alerts.passwordReset') }}</Message>
                </transition-group>
                <Button :label="$t('buttons.resetPassword')" class="p-button-raised p-button-info p-mr-2 p-mb-2" @click="resetUserPassword()" />

                <Button @click="submitForm()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
            </div>
        </div>
    </div>
</template>
<script>
import { BoApi } from '../../service/BoApi';
import { debounce } from 'lodash';
import settings from '../../settings/generalSettings';
export default {
    data() {
        return {
            BoApi: new BoApi(),
            showError: false,
            errorText: '',
            disableSubmit: false,
            backRoute: 'cashiers',
            errorKeyCount: 0,
            user: {
                active: false,
            },
            shops: [],
            shopsModel: [],
            transactionsModel: [],
            transactions: [
                { id: 'DEBIT', name: 'DEBIT' },
                { id: 'CREDIT', name: 'CREDIT' },
            ],
            roles: [],
            regions: [],
            franchises: [],
            successReset: false,
            passwordKeyCount: 0,
            allShopsAccess: false,
        };
    },
    watch: {
        shopsModel() {
            this.showError = false;
        },
        allShopsAccess() {
            if (this.allShopsAccess) {
                this.shopsModel = [];
            }
        },
    },
    mounted() {
        this.BoApi.getShops('')
            .then((responseShop) => {
                this.shops = responseShop.data;
                if (this.isEdit()) {
                    this.BoApi.getCashierDetails(this.$route.params.id).then((response) => {
                        this.user = response.data;
                        if (this.user.bettingOffices == null) {
                            this.allShopsAccess = true;
                        } else {
                            for (let i = 0; i < this.shops.length; i++) {
                                if (this.user.bettingOffices.indexOf(this.shops[i]._id) >= 0) {
                                    this.shopsModel.push(this.shops[i]);
                                }
                            }
                        }
                        let transactionsArray = [];
                        if (response.data.allowedTransactions != null) {
                            for (let i = 0; i < response.data.allowedTransactions.length; i++) {
                                var transactionObj = this.transactions.find((t) => t.id == response.data.allowedTransactions[i]);
                                transactionsArray.push(transactionObj);
                            }
                            this.transactionsModel = transactionsArray;
                        } else {
                            this.transactionsModel = [];
                        }
                    });
                }
            })
            .catch((error) => {
                this.checkForUnauthorizedError(error);
                this.errorText = error;
                this.showError = true;
            });
    },
    methods: {
        submitForm: function () {
            this.showError = false;
            this.disableSubmit = true;
            if (this.notNullOrEmptyField(this.user.username) && this.notNullOrEmptyField(this.user.lastName) && this.notNullOrEmptyField(this.user.firstName) && (this.allShopsAccess || this.notNullOrEmptyObject(this.shopsModel))) {
                if (!settings.useCNPValidation || (settings.useCNPValidation && (!this.notNullOrEmptyField(this.user.personalIdNumber) || (this.notNullOrEmptyField(this.user.personalIdNumber) && this.validateCNP(this.user.personalIdNumber))))) {
                    if (this.allShopsAccess) {
                        this.user.bettingOffices = null;
                    } else {
                        this.user.bettingOffices = this.shopsModel.map((item) => {
                            return item._id;
                        });
                    }
                    this.user.allowedTransactions = this.transactionsModel.map((item) => {
                        return item.id;
                    });
                    this.BoApi.saveCashier(this.user)
                        .then(() => {
                            this.goto(this.backRoute);
                        })
                        .catch((error) => {
                            this.disableSubmit = false;
                            this.checkForUnauthorizedError(error);
                            this.showError = true;
                            this.errorText = error;
                            this.errorKeyCount++;
                        });
                } else {
                    this.errorText = this.$t('system.alerts.invalidCNP');
                    this.showError = true;
                    this.disableSubmit = false;
                    this.errorKeyCount++;
                }
            } else {
                this.errorText = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.disableSubmit = false;
                this.errorKeyCount++;
            }
        },
        checkUsernameAvailability() {
            let user = { username: this.user.username };
            this.BoApi.isCashierUsernameAvailable(user)
                .then(() => {
                    this.showError = false;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    if (error.response && error.response.status === 409) {
                        this.showError = true;
                        this.errorText = this.$t('system.alerts.usernameTaken');
                        this.errorKeyCount++;
                    }
                });
        },
        resetUserPassword() {
            this.BoApi.resetCashierPassword(this.user._id)
                .then(() => {
                    this.successReset = true;
                    this.passwordKeyCount++;
                    this.user.paswordChangeNeeded = true;
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    if (error.response && error.response.status === 409) {
                        this.showError = true;
                        this.errorText = this.$t('alerts.generalError');
                        this.errorKeyCount++;
                    }
                });
        },
        isEdit() {
            return this.$route.params.id !== '0';
        },
        checkUsername: debounce(function () {
            this.checkUsernameAvailability();
        }, 800),
    },
};
</script>
